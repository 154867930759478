export default {
  ADD_ITEM(state, item) {
    state.NoticQRCodes.unshift(item);
  },
  SET_NoticQRCodes(state, NoticQRCodes) {
    state.NoticQRCodes = NoticQRCodes;
  },
  UPDATE_UintOwnerType(state, UintOwnerType) {
    const UintOwnerTypeIndex = state.NoticQRCodes.findIndex(p => p.ID == UintOwnerType.ID);
    if (UintOwnerTypeIndex != -1) {
      Object.assign(state.NoticQRCodes[UintOwnerTypeIndex], UintOwnerType);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.NoticQRCodes.findIndex(p => p.ID == itemId);
    state.NoticQRCodes.splice(ItemIndex, 1);
  }
};

