
import state from "./moduleNoticQRCodeState.js"
import mutations from "./moduleNoticQRCodeMutations.js"
import getters from "./moduleNoticQRCodeGetters.js"
import actions from './moduleNoticQRCodeActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
