<template>
   <div id="Home">
    <!-- <vs-alert
      color="danger"
      title="Qr-Code Not Valid"
      :active.sync="user_not_found"
    > -->
      <!-- <span>Qr-Code with id: {{ $route.params.ID }} not found. </span> -->
      <!-- <span>
        <span>Check </span>
        <router-link
          :to="{ name: 'page-user-list' }"
          class="text-inherit underline"
          >Qr-Code</router-link
        >
      </span> -->
    <!-- </vs-alert> -->

    <div class="qrcode-container">
    <qrcode-vue :value="decodedValue" :size="200" :fg-color="'#000'" :bg-color="'#fff'" />
 </div>
   </div>

</template>

<script>
//import QrcodeVue from 'vue-qrcode';
import QrcodeVue from '@chenfengyuan/vue-qrcode';
import moduleNoticQRCode from "@/store/NoticQRCode/moduleNoticQRCode.js";

export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      decodedValue: '',
    };
  },
created(){
  if (!moduleNoticQRCode.isRegistered) {
      this.$store.registerModule(
        "moduleNoticQRCodeList",
        moduleNoticQRCode
      );
      moduleNoticQRCode.isRegistered = true;
    }
    const ID = this.$route.params.ID;
    debugger;
    if (ID != undefined && ID != 0) {
      debugger;
      this.$store.dispatch("moduleNoticQRCodeList/GetItemByID", ID).then((res) => {
        debugger;
        this.decodedValue = res.data.data;
        console.log(this.decodedValue);
      });
    } else if (ID == undefined) {
      ID == 0;
    }
}
};
</script>
<style>
.qrcode-container {
 display: flex;
 justify-content: center;
 align-items: center;
 height: 100vh; /* Full viewport height */
 width: 100vw; /* Full viewport width */
}
</style>