import axios from "@/axios.js";

export default {
  GetItemByID(context, itemid) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .post("API/NoticeVisitAndRent/GenerateQrCode?Id=" + itemid)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
